<template>
  <div class="view-publish-opportunity">
    <div class="container">
      <div class="d-flex flex-column align-items-center">
        <div class="title-wrapper text-left">
          <h2 class="d-none d-lg-block text-uppercase">
            Publicar oportunidade
          </h2>
          <h3 class="d-lg-none text-uppercase">Publicar oportunidade</h3>
          <p class="mb-5">
            Encontre o profissional ideal para trabalhar em seus projetos.
          </p>
        </div>
        <div class="card">
          <h4 class="text-dark mb-4">Informações básicas</h4>
          <div class="row align-items-center mb-5">
            <div class="col-lg-6">
              <form-group
                select
                blackShape
                id="category"
                label="Profissão"
                v-model="form.category"
                placeholder="Selecione a profissão desejada..."
                :options="categories"
              />
            </div>
            <div class="col-lg-6">
              <select-dropdown
                id="select-tags"
                hideButtons
                emitOnSelect
                label="Tags"
                :options="tags"
                shortPlaceholder
                propName="name"
                placeholder="Selecione as tags..."
                v-model="form.tags"
                :errors="errors.tags"
              />
            </div>
          </div>
          <h4 class="text-dark mb-4">Detalhes do projeto</h4>
          <form-group
            v-model="form.title"
            id="title"
            label="Título do projeto"
            placeholder="Exemplo: Designer para landing pages"
          />
          <form-group
            textarea
            class="mb-4"
            id="briefing"
            label="Briefing"
            v-model="form.briefing"
            placeholder="Descreva seu projeto"
          />
          <h4 class="text-dark mb-4">Prazos</h4>
          <div class="d-flex align-items-center mb-5">
            <b-form-checkbox
              v-model="show_deadline"
              switch
              size="lg"
              class="text-dark mr-3"
            >
              <strong>Definir prazo de entrega</strong>
            </b-form-checkbox>
            <form-group
              isDate
              masked
              mask="##/##/####"
              v-if="show_deadline"
              v-model="form.deadline"
              id="deadline"
              placeholder="DD/MM/AAAA"
            />
          </div>
          <div
            class="d-none d-lg-flex justify-content-center align-items-center"
          >
            <button
              class="btn btn-outline-secondary px-lg-5 mr-2"
              @click="$router.go(-1)"
            >
              Cancelar
            </button>
            <button class="btn btn-primary px-lg-5" @click="submit">
              <loading :show="showLoading">
                Atualizar
              </loading>
            </button>
          </div>
          <div class="d-grid gap-2 d-lg-none">
            <button
              class="btn btn-outline-secondary btn-block px-lg-5 mb-4"
              @click="$router.go(-1)"
            >
              Cancelar
            </button>
            <button class="btn btn-primary btn-block px-lg-5" @click="submit">
              <loading :show="showLoading">
                Atualizar
              </loading>
            </button>
          </div>
        </div>
      </div>
      <div class="my-3 py-3"></div>
    </div>
  </div>
</template>
<script>
import { formFields, convertHTMLToPlainText } from "@/functions/helpers.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ...formFields(
        ["id", "category", "tags", "title", "briefing", "deadline", "user"],
        {
          user: this.$user.user.id,
          tags: [],
          category: ""
        }
      ),
      show_deadline: false,
      showLoading: false
    };
  },
  // watch: {
  //   "form.category"(cat_id) {
  //     this.$store.dispatch("professional/getTagsByCategories", cat_id).then();
  //   }
  // },
  computed: {
    ...mapState("professional", ["categories", "tags"])
  },
  mounted() {
    this.dispatch();
    this.$store.dispatch("professional/getTags").then();
  },
  methods: {
    dispatch() {
      this.showLoading = true;
      this.$store
        .dispatch("opportunity/getOne", this.$route.params.id)
        .then(data => {
          this.form = data;
          for (let key in data) {
            if (key == "category") {
              this.form[key] = data[key].id;
              continue;
            }

            if (key == "tags") {
              this.form[key] = data[key]?.map(({ id }) => id) || null;
              continue;
            }

            if (key == "user") {
              this.form[key] = this.$user.user.id;
              continue;
            }

            if (key == "briefing") {
              this.form[key] = convertHTMLToPlainText(data[key]);
              continue;
            }

            if (key == "deadline" && data[key]) {
              this.show_deadline = true;
              this.form[key] = data[key]
                .split("-")
                .reverse()
                .join("-");
            }
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    submit() {
      if (!this.form.category) {
        this.$message.warning("Escolha a profissão");
        return;
      }

      if (!this.form.tags || !this.form.tags.length) {
        this.$message.warning("Escolha as tags relacionadas");
        return;
      }

      if (!this.form.title || !this.form.title.length) {
        this.$message.warning("Digite o titulo da oportunidade");
        return;
      }

      if (!this.form.briefing || !this.form.briefing.length) {
        this.$message.warning("Digite o briefing da oportunidade");
        return;
      }

      this.showLoading = true;

      const payload = {
        ...this.form,
        deadline:
          this.form.deadline
            ?.split("/")
            .reverse()
            .join("-") || null
      };

      this.$store
        .dispatch("opportunity/update", payload)
        .then(() => {
          this.$message.success("A oportunidade foi atualizada!");
          this.$router.push({ name: "my-opportunities" });
        })
        .catch(err => {
          const error = err?.response?.data;
          const msg =
            error.error ||
            error.detail ||
            "Não foi possível atualizar a oportunidade";
          this.$message.error(msg);
        })
        .finally(() => {
          this.showLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-publish-opportunity {
  .card {
    background: #f5f5f5;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 20px 32px;
    border: 0;
    width: 848px;
    height: fit-content;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: fit-content;
    }
  }

  .title-wrapper {
    width: 848px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  strong {
    color: #202124;
    font-size: 14px;
    letter-spacing: 0.015em;
  }
}
</style>
